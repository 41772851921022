a.nav-link .fa {
    margin-right: 7px!important;
}.user-panel { 
    box-shadow: 5px 5px 5px #cccccc59; 
}.sidemenu-container .sidemenu, .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu
{padding-bottom: 120px;}
.sidemenu-container .sidemenu .sub-menu li>a>i, .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>a>i {
    margin-right: 10px;
    
} .sub-menuvs {
    background-color: #F4F6F9;
}.sub-menuvs li {
    background: 0 0;
    margin: 0;
    padding: 0;
}.sub-menuvs>li>a {
    color: #444;
}.sub-menuvs li>a>i {
    margin-right: 10px;
}
.sidemenu{
overflow-y: auto;
height: 100vh;}
::-webkit-scrollbar {
    width: 5px;
  }
 
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background:rgb(158, 165, 171);
  }
   
  ::-webkit-scrollbar-thumb:hover {
    background:rgb(158, 165, 171);
  }

  #MobileMenuDivWrapper { 
    height: 200px;
    max-height: 200px;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling:touch; 
  }