.password-input {
    position: relative;
  }
  
  .password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .password-toggle.visible {
    color: #007bff; /* Change color when the password is visible */
  }
  