.checkout_container {
  background: #fff;
  margin-top: 10px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: [row] auto [row] auto [row];
}

.checkout_header {
  grid-column: col / span 4;
  grid-row: row 1;
  height: 60px;
  background: blue;
  color: #fff;
  padding: 12px;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.checkout_header > span {
  padding-right: 10px;
}

.checkout_total {
  border-radius: 3px;
  border: #eee 1px solid;
  margin-right: 5px;
  margin-bottom: 5px;
  min-height: 150px;
}

.checkout_details {
  grid-column: col 1 / span 3;
  grid-row: row 2;
  min-height: 300px;
  margin-left: 5px;
  border-radius: 3px;
  margin-bottom: 5px;
  border: #eee 1px solid;
}

.shipping_address {
  margin-top: 30px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}
.billing_address_header {
  border-bottom: #eee 1px solid;
  height: 30px;
  font-weight: bold;
}
.ship_address_header {
  border-bottom: #eee 1px solid;
  height: 30px;
  font-weight: bold;
}
.cat_no_data {
  display: grid;
  justify-content: center;
  height: 25rem;
  align-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: #696969;
}
.cat_no_data > span {
  font-size: 5rem;
  color: skyblue;
  text-align: center;
}
.form_loader {
  padding: 10px;
  grid-column: 1/-1;
}
.place_order {
  grid-row: 1;
  min-height: 70px;
  text-align: center;
  margin-top: 10px;
}
.address_header {
  grid-row: 1;
  grid-column: 1/3;
  background: #eee;
  color: #111;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
}
.billing_address {
  grid-row: 2;
  grid-column: 1/2;
  min-height: 150px;
  padding: 5px;
  border: 1px solid #eee;
  margin: 5px;
}
.ship_address {
  grid-row: 2;
  grid-column: 2/2;
  min-height: 150px;
  padding: 5px;
  border: 1px solid #eee;
  margin: 5px;
}

.checkout_details_header {
  grid-column: 1 / 3;
  grid-row: 1;
  height: 45px;
  background: #111;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
}

.th_head {
  font-weight: bold;
}
.grid_table {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
}
.grid_table > span {
  padding: 8px 4px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  text-align: center;
}

.checkout_details_item {
  padding: 10px;
}
.checkout_total_header {
  height: 45px;
  padding: 10px;
  background: #eee;
  font-weight: bold;
  text-transform: uppercase;
}
.total_body {
  display: grid;
  border-bottom: 1px solid #eee;
}

.total_body > span {
  padding: 8px 4px;
  border-left: 1px solid #eee;
  font-weight: bold;
  color: #696969;

  font-size: 14px;
}
._span_right {
  float: right;
  margin-right: 10px;
  color: #696969;
  font-size: 13px;
}

@media only screen and (max-width: 600px) {
  .checkout_details {
    grid-column: 1 /-1;
  }
  .checkout_total {
    grid-column: 1 /-1;
    margin-left: 5px;
  }
}
