@media (max-width: 400px) {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100% !important;
  }
}

@media only screen and (min-width: 400px) {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .array-item > .col-xs-9 {
    width: 100%;
  }
}

select.form-control:not([size]):not([multiple]) {
  height: 35px;
}

.react-tel-input .form-control {
  width: 100%;
  height: 35px;
}

.ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected,
.ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected:hover {
  outline-color: var(--ck-color-widget-blurred-border);
  top: 10px;
}

.btnBackCss {
  position: absolute;
  top: 25px;
  z-index: 50;
  left: 93%;
}
select option {
  background-color: white;
  font-weight: normal;
  color: black;
}
select:focus > option:checked {
  background: skyblue !important;
}

button.btn.btn-info {
  width: auto;
}

button.btn.btn-primary {
  width: auto;
}

.array-item-remove {
  max-width: 60px !important;
}

.array-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100% !important;
}

.array-item > .col-xl-9 {
  width: 30% !important;
}

@media only screen and (max-width: 600px) {
  .multiSelectList {
    width: 100% !important;
  }
  .btnBackCss {
    left: 80%;
  }
}

ul.error-detail.bs-callout.bs-callout-info {
  padding: 0px;
}
.text-danger {
  list-style: none;
}
.form-group {
  margin-bottom: 1rem;
}
.row {
  margin-bottom: -2px;
}
.form-control {
  height: 35px;
  font-size: 12px;
}
.multiSelectList {
  list-style: none;
  width: 100%;
  margin-left: -20px;
  border-radius: 2px;
  padding: 5px;
  height: 45px;
  border: 1px solid #ccc;
}
.form-group.field.field-boolean {
  margin-top: 20px;
}
.form-group.field.field-array {
  margin-left: 0px;
  width: 100%;
}
.col-xs-3.array-item-toolbox {
  margin-left: 30px;
  width: auto;
}
.modal-body::-webkit-scrollbar {
  width: 20px;
}

.form-group.field.field-object {
  margin-left: 5px;
}

#root__title {
  font-weight: bold !important;
  font-size: 15px;
  color: black;
}
.col-xs-12 {
  width: 96%;
}
legend {
  border: 0;
  padding: 0;
  margin-left: -5px;
  font-weight: normal !important;
  font-size: 13px;
}
p.array-item-add {
  margin-left: 12px;
}
.array-item > .col-xs-9 {
  width: 40%;
}
.array-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  /*  width: 48.5% !important; */
  float: left;
}
.array-item {
  margin-right: 1px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: contents;
}

button.btn.btn-default.array-item-move-up {
  padding: 0px;
  height: 20px;
  top: 0px !important;
  margin-right: -34px;
  font-size: 9px;
}

button.btn.btn-default.array-item-move-down {
  padding: 0px;
  margin-top: 22px;
  margin-left: 3px !important;
  top: 1px !important;
  height: 20px;
  font-size: 9px;
}
.col-xs-3.array-item-toolbox {
  margin-left: 5px;
}
#root_Sections .col-xs-3.array-item-toolbox {
  margin-top: 20px !important;
}
@media only screen and (max-width: 768px) {
  .array-item > .col-xs-9 {
    width: 50%;
  }
  .array-item {
    width: 100% !important;
    float: left;
  }

  .col-xs-3.array-item-toolbox {
    margin-left: 10px;
    width: 100px;
  }
  .array-item .btn {
    margin-top: 0;
  }
  button.btn.btn-default.array-item-move-up {
    margin-right: -50px;
  }
  #root_Sections .col-xs-3.array-item-toolbox {
    margin-top: 0px !important;
  }

  .col-xs-3.array-item-toolbox {
    margin-left: 10px;
    width: 100px;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
}

button.btn.btn-danger.array-item-remove {
  margin-left: 0px;
}

.ck.ck-editor__editable_inline > :first-child {
  margin-top: 0px !important;
}
