.react-tel-input .form-control {
  width: 100%;
  height: 45px;
  margin-top: 4px;
}

.fakeimg {
  height: 200px;
  background: #aaa;
}
.GTRY {
  border: 1px solid #dee2e6;
  margin-top: 75px;
  padding-bottom: 20px;
  background-color: #fff;
}
.main_head {
  width: 100%;
  height: 66px;
  background-color: #fff;
  margin-bottom: 52px;
  padding-top: 10px;
}
.main-logo {
  width: 250px;
  height: 66px;
  display: inline-block;
}
.card-box {
  background: #fff;
  min-height: 50px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 20px;
  transition: 0.5s;
  border: 1px solid #f2f2f2;
  border-radius: 7px;
  margin-top: 10px;
}
.LKJH {
  display: flex;
}
.GTR {
  text-align: left;
}
.JUI {
  text-align: right;
}
.btn-info {
  color: #fff;
  background-color: #15b4e9;
  border-color: #15b4e9;
}
.btn-info:hover {
  color: #fff;
  background-color: #15b4e9;
  border-color: #15b4e9;
}
.btn-reg {
  color: #15b4e9;
  background-color: #ffffff;
  border-color: #15b4e9;
}
.btn-reg:hover {
  color: #15b4e9;
  background-color: #ffffff;
  border-color: #15b4e9;
}
.SYTU {
  display: flex;
}
.MLKI {
  float: right;
  margin-bottom: 12px;
}
.tn-uvs90,
button.btn.btn-reg {
  width: 100%;
}

.login-btn {
  width: 100% !important;
  display: block !important;
}

@media only screen and (max-width: 667px) {
  .MLKI {
    float: inherit;
  }
  button.btn.btn-reg {
    margin-top: 20px;
    width: 210px;
  }

  .login-btn {
    width: 210px !important;    
  }
}
.MLKI a {
  color: #212529;
}
.KNLO {
  color: #878787;
}
.form-check-label {
  margin-left: 17px;
  margin-bottom: 12px;
}
input:focus {
  border-color: #ccc ;
}

@media only screen and (max-width: 600px) {
  .KLO1 {
    display: none;
  }
  .MLKI a {
    font-size: 12px;
  }
  .form-check-label {
    font-size: 12px;
    margin-bottom: 14px;
  }
  .MKUY {
    text-align: center;
    padding-top: 10px;
  }
  .main_head {
    margin-bottom: 12px;
    padding-top: 10px;
    border-bottom: 1px dashed #eeeeee;
  }
  button {
    margin-top: 20px;
    width: 250px;
  }
  button.btn.btn-info.btn-md.btn-fb.metro {
    margin-top: 10px;
  }
  .GTRY {
    border: 0px solid #dee2e6;
    margin-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
  }
  .fb-uvs,
  .tn-uvs90 {
    width: 207px;
  }
  .fb-uvs {
    margin-bottom: 20px;
  }
}

.form-check-input {
  position: static !important;
}

.modal .modal-header {
  background: #eaeef5;
  padding: 15px;
}
.modal .modal-content {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0;
  border: 0;
}
.modal .modal-header .close {
  margin-top: -16px !important;
  font-size: 32px;
  font-weight: 400;
}
.modal-body {
  padding: 30px;
}

.uvsradio label {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #d2d2d2;
  border-radius: 3px;
  opacity: 1;
  margin-bottom: 20px;
}

.uvsradio label.uvsboxpopuvs {
  background: #f3f8ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #f3f8ff52;
  border: 2px solid #2a46ff;
  border-radius: 3px;
  color: #2a46ff;
  opacity: 1;
  margin-bottom: 20px;
}
.uvstablepop td {
  padding: 10px;
  font-size: 20px;
}
.uvstablepop td img {
  width: 90px;
  height: 45px;
}
