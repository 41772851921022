.Rectangle-353 {
  width: 264px;
  height: 160px;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgba(141, 123, 123, 0.1);
  background-color: #ffffff;
}
.Rectangle-354 {
  width: 264px;
  height: 2px;
  box-shadow: 0 2px 10px 0 rgba(0, 171, 246, 0.11);
  background-color: #3bc9d6;
}
.-Oct-2019 {
  
  height: 16px;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #c9c9c9;
}
.NxgLabs-Invoice {
  width: 100%;
  height: 21px;
  font-family: SegoeUI;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #000651;
display:inline-block;
}
.invoice_no{
display:inline-block;
  width:100%;
  height: 21px;
  font-family: SegoeUI;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: right;
  color: #c4621e;

}
.invoice_desc{

 width: 219px;
  height: 48px;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #5f6181;
}
.invoice_amt {
  width: 50%;
  height: 16px;
  font-family: SegoeUI;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #eb9626;
display:inline-block;
}
.in_edit {
  width: 50%;
  height: 16px;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #1e2361;
display:inline-block;
}
.LOKI{
    width: 100%;
    display: flex;

}
.MHY{
    width: 100%;
   
}
.in_main{
padding:12px;
}




.modal-footer {
    display: block;
  }

  .main {
    width: 100%;
    display: flex;
  }

  .icon-d {
    width: 4%;
    display: block;
  }

  .icon-d i {
    font-size: 24px;
    padding: 5px;
  }

  .heading-f {
    width: 96%;
    display: block;
    padding: 5px;
  }

  .main1 {
    width: 100%;
    display: flex;
  }

  .icon-d1 {
    width: 4%;
    display: block;
  }

  .icon-d1 i {
    font-size: 24px;
    padding: 5px;
  }

  .heading-f1 {
    width: 96%;
    display: block;
    padding: 5px;
  }

  .main2 {
    width: 100%;
    display: flex;
  }

  .icon-d2 {
    width: 4%;
    display: block;
  }

  .icon-d2 i {
    font-size: 24px;
    padding: 5px;
  }

  .heading-f2 {
    width: 96%;
    display: block;
    padding: 5px;
  }

  .container-w::after {
    content: "";
    clear: both;
    display: table;
  }

  .container-w img {
    float: left;
    max-width: 60px;
    width: 100%;
    margin-right: 20px;
    border-radius: 50%;
    height: 54px;
  }

  .container-w img.right {
    float: right;
    margin-left: 20px;
    margin-right: 0;
  }

  .time-right {
    float: right;
    color: #aaa;
  }

  .time-left {
    float: left;
    color: #999;
  }

  .heading-f h5 {
    margin-top:0px;
  }
  .heading-f {
    padding-left:10px;
  }

  .modal{
    overflow: visible !important;
 }