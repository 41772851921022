.uvs-tests {
  padding: 10px;
  border: 2px solid #4444443b;
  margin-bottom: 30px;
}
.uvs-tests td {
  font-weight: 600;
  padding: 10px;
  vertical-align: top;
}
.uvs-tests h2 {
  margin-top: 0;
  font-size: 28px;
}
/* td img {
  width: 100%;
} */
/* table {
  width: 100%;
} */
