.customdd-content {
    margin-top: 5px;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    max-height: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 3px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.customdd-element {
    padding: 7px;
    font-size: 13px
}

.customdd-noelement {
    padding: 8px;
    font-size: 14px
}

.customdd-element:hover {
    background-color: #d9d9d9;
}