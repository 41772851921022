

  
  [class*="loader-"] {
    display: inline-block;
    width: 1em;
    height: 1em;
    color: inherit;
    vertical-align: middle;
    pointer-events: none;
  }
  
  .loader-01 {
    border: 0.2em dotted currentcolor;
    border-radius: 50%;
    -webkit-animation: 1s loader-01 linear infinite;
            animation: 1s loader-01 linear infinite;
  }
  
  @-webkit-keyframes loader-01 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-01 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loader-02 {
    border: 0.2em solid transparent;
    border-left-color: currentcolor;
    border-right-color: currentcolor;
    border-radius: 50%;
    -webkit-animation: 1s loader-02 linear infinite;
            animation: 1s loader-02 linear infinite;
  }
  
  @-webkit-keyframes loader-02 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-02 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loader-03 {
    border: 0.2em solid currentcolor;
    border-bottom-color: transparent;
    border-radius: 50%;
    -webkit-animation: 1s loader-03 linear infinite;
            animation: 1s loader-03 linear infinite;
    position: relative;
  }
  
  @-webkit-keyframes loader-03 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-03 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loader-04 {
    border: 1px solid currentcolor;
    border-radius: 50%;
    -webkit-animation: 1s loader-04 linear infinite;
            animation: 1s loader-04 linear infinite;
    position: relative;
  }
  .loader-04:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: -0.2em;
    left: 50%;
    border: 0.2em solid currentcolor;
    border-radius: 50%;
  }
  
  @-webkit-keyframes loader-04 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-04 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loader-05 {
    border: 0.2em solid transparent;
    border-top-color: currentcolor;
    border-radius: 50%;
    -webkit-animation: 1s loader-05 linear infinite;
            animation: 1s loader-05 linear infinite;
    position: relative;
  }
  .loader-05:before {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    top: -0.2em;
    left: -0.2em;
    border: 0.2em solid currentcolor;
    border-radius: 50%;
    opacity: 0.5;
  }
  
  @-webkit-keyframes loader-05 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-05 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loader-06 {
    border: 0.2em solid currentcolor;
    border-radius: 50%;
    -webkit-animation: loader-06 1s ease-out infinite;
            animation: loader-06 1s ease-out infinite;
  }
  
  @-webkit-keyframes loader-06 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0;
    }
  }
  
  @keyframes loader-06 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0;
    }
  }
  .loader-07 {
    border: 0 solid transparent;
    border-radius: 50%;
    position: relative;
  }
  .loader-07:before, .loader-07:after {
    content: "";
    border: 0.2em solid currentcolor;
    border-radius: 50%;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: loader-07 1s linear infinite;
            animation: loader-07 1s linear infinite;
    opacity: 0;
  }
  .loader-07:before {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .loader-07:after {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  @-webkit-keyframes loader-07 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0;
    }
  }
  
  @keyframes loader-07 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0;
    }
  }
  .loader-08 {
    position: relative;
  }
  .loader-08:before, .loader-08:after {
    content: "";
    width: inherit;
    height: inherit;
    border-radius: 50%;
    background-color: currentcolor;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: loader-08 2s infinite ease-in-out;
            animation: loader-08 2s infinite ease-in-out;
  }
  .loader-08:after {
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  
  @-webkit-keyframes loader-08 {
    0%,
      100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes loader-08 {
    0%,
      100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  .loader-09 {
    background-color: currentcolor;
    border-radius: 50%;
    -webkit-animation: loader-09 1s infinite ease-in-out;
            animation: loader-09 1s infinite ease-in-out;
  }
  
  @-webkit-keyframes loader-09 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0;
    }
  }
  
  @keyframes loader-09 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0;
    }
  }
  .loader-10 {
    position: relative;
    -webkit-animation: loader-10-1 2s infinite linear;
            animation: loader-10-1 2s infinite linear;
  }
  .loader-10:before, .loader-10:after {
    content: "";
    width: 0;
    height: 0;
    border: 0.5em solid currentcolor;
    display: block;
    position: absolute;
    border-radius: 100%;
    -webkit-animation: loader-10-2 2s infinite ease-in-out;
            animation: loader-10-2 2s infinite ease-in-out;
  }
  .loader-10:before {
    top: 0;
    left: 50%;
  }
  .loader-10:after {
    bottom: 0;
    right: 50%;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
  }
  
  @-webkit-keyframes loader-10-1 {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-10-1 {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader-10-2 {
    0%,
      100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes loader-10-2 {
    0%,
      100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  .loader-11 {
    background-color: currentcolor;
    -webkit-animation: loader-11 1.2s infinite ease-in-out;
            animation: loader-11 1.2s infinite ease-in-out;
  }
  
  @-webkit-keyframes loader-11 {
    0% {
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
              transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
              transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
              transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  
  @keyframes loader-11 {
    0% {
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
              transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
              transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
              transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  .loader-12 {
    position: relative;
  }
  .loader-12:before, .loader-12:after {
    content: "";
    display: block;
    position: absolute;
    background-color: currentcolor;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 50%;
    box-shadow: -0.5em 0 0 currentcolor;
    -webkit-animation: loader-12 1s linear infinite;
            animation: loader-12 1s linear infinite;
  }
  .loader-12:after {
    top: 50%;
    bottom: 0;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  
  @-webkit-keyframes loader-12 {
    0%,
      100% {
      box-shadow: -0.5em 0 0 transparent;
      background-color: currentcolor;
    }
    50% {
      box-shadow: -0.5em 0 0 currentcolor;
      background-color: transparent;
    }
  }
  
  @keyframes loader-12 {
    0%,
      100% {
      box-shadow: -0.5em 0 0 transparent;
      background-color: currentcolor;
    }
    50% {
      box-shadow: -0.5em 0 0 currentcolor;
      background-color: transparent;
    }
  }
  .loader-13:before,
  .loader-13:after,
  .loader-13 {
    border-radius: 50%;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation: loader-13 1.8s infinite ease-in-out;
            animation: loader-13 1.8s infinite ease-in-out;
  }
  
  .loader-13 {
    color: currentcolor;
    position: relative;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
    top: -1em;
  }
  .loader-13:before {
    right: 100%;
    -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
  }
  .loader-13:after {
    left: 100%;
  }
  .loader-13:before, .loader-13:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: inherit;
    height: inherit;
  }
  
  @-webkit-keyframes loader-13 {
    0%,
      80%,
      100% {
      box-shadow: 0 1em 0 -1em;
    }
    40% {
      box-shadow: 0 1em 0 -0.2em;
    }
  }
  
  @keyframes loader-13 {
    0%,
      80%,
      100% {
      box-shadow: 0 1em 0 -1em;
    }
    40% {
      box-shadow: 0 1em 0 -0.2em;
    }
  }
  .loader-14 {
    border-radius: 50%;
    box-shadow: 0 1em 0 -0.2em currentcolor;
    position: relative;
    -webkit-animation: loader-14 0.8s ease-in-out alternate infinite;
            animation: loader-14 0.8s ease-in-out alternate infinite;
    -webkit-animation-delay: 0.32s;
            animation-delay: 0.32s;
    top: -1em;
  }
  .loader-14:after, .loader-14:before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    box-shadow: inherit;
    -webkit-animation: inherit;
            animation: inherit;
  }
  .loader-14:before {
    left: -1em;
    -webkit-animation-delay: 0.48s;
            animation-delay: 0.48s;
  }
  .loader-14:after {
    right: -1em;
    -webkit-animation-delay: 0.16s;
            animation-delay: 0.16s;
  }
  
  @-webkit-keyframes loader-14 {
    0% {
      box-shadow: 0 2em 0 -0.2em currentcolor;
    }
    100% {
      box-shadow: 0 1em 0 -0.2em currentcolor;
    }
  }
  
  @keyframes loader-14 {
    0% {
      box-shadow: 0 2em 0 -0.2em currentcolor;
    }
    100% {
      box-shadow: 0 1em 0 -0.2em currentcolor;
    }
  }
  .loader-15 {
    background: currentcolor;
    position: relative;
    -webkit-animation: loader-15 1s ease-in-out infinite;
            animation: loader-15 1s ease-in-out infinite;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
    width: 0.25em;
    height: 0.5em;
    margin: 0 0.5em;
  }
  .loader-15:after, .loader-15:before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background: inherit;
    -webkit-animation: inherit;
            animation: inherit;
  }
  .loader-15:before {
    right: 0.5em;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .loader-15:after {
    left: 0.5em;
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  
  @-webkit-keyframes loader-15 {
    0%,
      100% {
      box-shadow: 0 0 0 currentcolor, 0 0 0 currentcolor;
    }
    50% {
      box-shadow: 0 -0.25em 0 currentcolor, 0 0.25em 0 currentcolor;
    }
  }
  
  @keyframes loader-15 {
    0%,
      100% {
      box-shadow: 0 0 0 currentcolor, 0 0 0 currentcolor;
    }
    50% {
      box-shadow: 0 -0.25em 0 currentcolor, 0 0.25em 0 currentcolor;
    }
  }
  .loader-16 {
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
    -webkit-perspective: 1000px;
            perspective: 1000px;
    border-radius: 50%;
  }
  .loader-16:before, .loader-16:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
            animation: 1s spin linear infinite;
  }
  .loader-16:before {
    -webkit-transform: rotateX(70deg);
            transform: rotateX(70deg);
  }
  .loader-16:after {
    -webkit-transform: rotateY(70deg);
            transform: rotateY(70deg);
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  
  @-webkit-keyframes rotate {
    0% {
      -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
              transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
              transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }
  
  @keyframes rotate {
    0% {
      -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
              transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
              transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }
  @-webkit-keyframes rotateccw {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
              transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(-360deg);
              transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
  @keyframes rotateccw {
    0% {
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
              transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(-360deg);
              transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
  @-webkit-keyframes spin {
    0%,
      100% {
      box-shadow: 0.2em 0px 0 0px currentcolor;
    }
    12% {
      box-shadow: 0.2em 0.2em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 0.2em 0 0px currentcolor;
    }
    37% {
      box-shadow: -0.2em 0.2em 0 0 currentcolor;
    }
    50% {
      box-shadow: -0.2em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -0.2em -0.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -0.2em 0 0 currentcolor;
    }
    87% {
      box-shadow: 0.2em -0.2em 0 0 currentcolor;
    }
  }
  @keyframes spin {
    0%,
      100% {
      box-shadow: 0.2em 0px 0 0px currentcolor;
    }
    12% {
      box-shadow: 0.2em 0.2em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 0.2em 0 0px currentcolor;
    }
    37% {
      box-shadow: -0.2em 0.2em 0 0 currentcolor;
    }
    50% {
      box-shadow: -0.2em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -0.2em -0.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -0.2em 0 0 currentcolor;
    }
    87% {
      box-shadow: 0.2em -0.2em 0 0 currentcolor;
    }
  }
  .loader-17 {
    position: relative;
    background-color: currentcolor;
    border-radius: 50%;
  }
  .loader-17:after, .loader-17:before {
    content: "";
    position: absolute;
    width: 0.25em;
    height: 0.25em;
    border-radius: 50%;
    opacity: 0.8;
  }
  .loader-17:after {
    left: -0.5em;
    top: -0.25em;
    background-color: currentcolor;
    -webkit-transform-origin: 0.75em 1em;
            transform-origin: 0.75em 1em;
    -webkit-animation: loader-17 1s linear infinite;
            animation: loader-17 1s linear infinite;
    opacity: 0.6;
  }
  .loader-17:before {
    left: -1.25em;
    top: -0.75em;
    background-color: currentcolor;
    -webkit-transform-origin: 1.5em 1em;
            transform-origin: 1.5em 1em;
    -webkit-animation: loader-17 2s linear infinite;
            animation: loader-17 2s linear infinite;
  }
  
  @-webkit-keyframes loader-17 {
    0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
              transform: rotateZ(0deg) translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
              transform: rotateZ(360deg) translate3d(0, 0, 0);
    }
  }
  
  @keyframes loader-17 {
    0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
              transform: rotateZ(0deg) translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
              transform: rotateZ(360deg) translate3d(0, 0, 0);
    }
  }
  .loader-18 {
    position: relative;
  }
  .loader-18:before, .loader-18:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
    border: 0.1em solid transparent;
    border-bottom-color: currentcolor;
    top: 0;
    left: 0;
    -webkit-animation: 1s loader-18 linear infinite;
            animation: 1s loader-18 linear infinite;
  }
  .loader-18:before {
    width: 1em;
    height: 1em;
  }
  .loader-18:after {
    width: 0.8em;
    height: 0.8em;
    top: 0.1em;
    left: 0.1em;
    animation-direction: reverse;
  }
  
  @-webkit-keyframes loader-18 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-18 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loader-19 {
    border-top: 0.2em solid currentcolor;
    border-right: 0.2em solid transparent;
    -webkit-animation: loader-19 1s linear infinite;
            animation: loader-19 1s linear infinite;
    border-radius: 100%;
    position: relative;
  }
  
  @-webkit-keyframes loader-19 {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-19 {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loader-20 {
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 0.1em currentcolor;
    border-radius: 50%;
    position: relative;
  }
  .loader-20:after, .loader-20:before {
    position: absolute;
    content: "";
    background-color: currentcolor;
    top: 0.5em;
    left: 0.5em;
    height: 0.1em;
    -webkit-transform-origin: left center;
            transform-origin: left center;
  }
  .loader-20:after {
    width: 0.4em;
    -webkit-animation: loader-20 2s linear infinite;
            animation: loader-20 2s linear infinite;
  }
  .loader-20:before {
    width: 0.3em;
    -webkit-animation: loader-20 8s linear infinite;
            animation: loader-20 8s linear infinite;
  }
  
  @-webkit-keyframes loader-20 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-20 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loader-21 {
    position: relative;
  }
  .loader-21:before, .loader-21:after {
    position: absolute;
    content: "";
  }
  .loader-21:before {
    width: 80%;
    height: 80%;
    left: 10%;
    bottom: 10%;
    border-radius: 100% 100% 100% 0;
    box-shadow: 0px 0px 0px 0.1em currentcolor;
    -webkit-animation: loader-21 1s linear infinite;
            animation: loader-21 1s linear infinite;
    -webkit-transform: rotate(-46deg);
            transform: rotate(-46deg);
  }
  .loader-21:after {
    width: 1em;
    height: 0.3em;
    border-radius: 100%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
    bottom: -0.2em;
    z-index: -1;
  }
  
  @-webkit-keyframes loader-21 {
    0% {
      top: 0;
    }
    50% {
      top: -5px;
    }
    100% {
      top: 0;
    }
  }
  
  @keyframes loader-21 {
    0% {
      top: 0;
    }
    50% {
      top: -5px;
    }
    100% {
      top: 0;
    }
  }
  .loader-22 {
    border: 0.1em currentcolor solid;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  .loader-22:after, .loader-22:before {
    position: absolute;
    content: "";
    background-color: currentcolor;
  }
  .loader-22:after {
    width: 50%;
    height: 0.1em;
    left: 50%;
    top: 50%;
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation: loader-22 2s linear infinite alternate;
            animation: loader-22 2s linear infinite alternate;
  }
  .loader-22:before {
    width: 100%;
    height: 40%;
    left: 0;
    bottom: 0;
  }
  
  @-webkit-keyframes loader-22 {
    0% {
      -webkit-transform: rotate(-160deg);
              transform: rotate(-160deg);
    }
    100% {
      -webkit-transform: rotate(-20deg);
              transform: rotate(-20deg);
    }
  }
  
  @keyframes loader-22 {
    0% {
      -webkit-transform: rotate(-160deg);
              transform: rotate(-160deg);
    }
    100% {
      -webkit-transform: rotate(-20deg);
              transform: rotate(-20deg);
    }
  }
  .loader-23 {
    height: 0.5em;
    border: 0.1em currentcolor solid;
    border-radius: 0.1em;
    position: relative;
    -webkit-animation: loader-23 5s linear infinite;
            animation: loader-23 5s linear infinite;
  }
  .loader-23:after {
    width: 0.07em;
    height: 100%;
    background-color: currentcolor;
    border-radius: 0px 0.5em 0.5em 0px;
    position: absolute;
    content: "";
    top: 0;
    left: calc(100% + 0.1em);
  }
  
  @-webkit-keyframes loader-23 {
    0% {
      box-shadow: inset 0px 0px 0px currentcolor;
    }
    100% {
      box-shadow: inset 1em 0px 0px currentcolor;
    }
  }
  
  @keyframes loader-23 {
    0% {
      box-shadow: inset 0px 0px 0px currentcolor;
    }
    100% {
      box-shadow: inset 1em 0px 0px currentcolor;
    }
  }
  .loader-24 {
    width: 0.8em;
    height: 1em;
    border: 0.1em currentcolor solid;
    border-radius: 0px 0px 0.2em 0.2em;
    position: relative;
  }
  .loader-24:after, .loader-24:before {
    position: absolute;
    content: "";
  }
  .loader-24:after {
    width: 0.2em;
    height: 50%;
    border: 0.1em currentcolor solid;
    border-left: none;
    border-radius: 0px 0.5em 0.5em 0px;
    left: calc(100% + 0.1em);
    top: 0.1em;
  }
  .loader-24:before {
    width: 0.1em;
    height: 0.3em;
    background-color: currentcolor;
    top: -0.3em;
    left: 0.05em;
    box-shadow: 0.2em 0px 0px 0px currentcolor, 0.2em -0.2em 0px 0px currentcolor, 0.4em 0px 0px 0px currentcolor;
    -webkit-animation: loader-24 1s linear infinite alternate;
            animation: loader-24 1s linear infinite alternate;
  }
  
  @-webkit-keyframes loader-24 {
    0% {
      height: 0px;
    }
    100% {
      height: 6px;
    }
  }
  
  @keyframes loader-24 {
    0% {
      height: 0px;
    }
    100% {
      height: 6px;
    }
  }
  .loader-25 {
    border: 0.1em currentcolor solid;
    position: relative;
    -webkit-animation: loader-25-1 5s linear infinite;
            animation: loader-25-1 5s linear infinite;
  }
  .loader-25:after {
    width: 0.2em;
    height: 0.2em;
    position: absolute;
    content: "";
    background-color: currentcolor;
    bottom: calc(100% + 0.2em);
    left: -0.4em;
    -webkit-animation: loader-25-2 1s ease-in-out infinite;
            animation: loader-25-2 1s ease-in-out infinite;
  }
  
  @-webkit-keyframes loader-25-1 {
    0% {
      box-shadow: inset 0 0 0 0 currentcolor;
    }
    100% {
      box-shadow: inset 0 -1em 0 0 currentcolor;
    }
  }
  
  @keyframes loader-25-1 {
    0% {
      box-shadow: inset 0 0 0 0 currentcolor;
    }
    100% {
      box-shadow: inset 0 -1em 0 0 currentcolor;
    }
  }
  @-webkit-keyframes loader-25-2 {
    25% {
      left: calc(100% + 0.2em);
      bottom: calc(100% + 0.2em);
    }
    50% {
      left: calc(100% + 0.2em);
      bottom: -0.4em;
    }
    75% {
      left: -0.4em;
      bottom: -0.4em;
    }
    100% {
      left: -0.4em;
      bottom: calc(100% + 0.2em);
    }
  }
  @keyframes loader-25-2 {
    25% {
      left: calc(100% + 0.2em);
      bottom: calc(100% + 0.2em);
    }
    50% {
      left: calc(100% + 0.2em);
      bottom: -0.4em;
    }
    75% {
      left: -0.4em;
      bottom: -0.4em;
    }
    100% {
      left: -0.4em;
      bottom: calc(100% + 0.2em);
    }
  }
  .loader-26 {
    width: 0.5em;
    height: 0.5em;
    background-color: currentcolor;
    box-shadow: 1em 0px 0px currentcolor;
    border-radius: 50%;
    -webkit-animation: loader-26 1s ease-in-out infinite alternate;
            animation: loader-26 1s ease-in-out infinite alternate;
  }
  
  @-webkit-keyframes loader-26 {
    0% {
      opacity: 0.1;
      -webkit-transform: rotate(0deg) scale(0.5);
              transform: rotate(0deg) scale(0.5);
    }
    100% {
      opacity: 1;
      -webkit-transform: rotate(360deg) scale(1.2);
              transform: rotate(360deg) scale(1.2);
    }
  }
  
  @keyframes loader-26 {
    0% {
      opacity: 0.1;
      -webkit-transform: rotate(0deg) scale(0.5);
              transform: rotate(0deg) scale(0.5);
    }
    100% {
      opacity: 1;
      -webkit-transform: rotate(360deg) scale(1.2);
              transform: rotate(360deg) scale(1.2);
    }
  }
  .loader-27 {
    box-shadow: inset 0 0 0 0.1em currentcolor;
    border-radius: 50%;
    position: relative;
    margin-left: 1.2em;
  }
  .loader-27:before {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    position: absolute;
    right: 1.2em;
    top: 0;
    box-shadow: inset 0 0 0 0.1em currentcolor;
  }
  .loader-27:after {
    border: 0.2em solid currentcolor;
    box-shadow: -1.2em 0 0 0 currentcolor;
    width: 0;
    height: 0;
    border-radius: 50%;
    left: 50%;
    top: 25%;
    position: absolute;
    content: "";
    -webkit-animation: loader-27 2s linear infinite alternate;
            animation: loader-27 2s linear infinite alternate;
  }
  
  @-webkit-keyframes loader-27 {
    0% {
      left: 0;
    }
    100% {
      left: 0.5em;
    }
  }
  
  @keyframes loader-27 {
    0% {
      left: 0;
    }
    100% {
      left: 0.5em;
    }
  }
  .loader-28 {
    position: relative;
    -webkit-animation: 2s loader-28-1 infinite;
            animation: 2s loader-28-1 infinite;
  }
  .loader-28:before {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    border-radius: 80% 20%;
    border: 0.1em solid currentcolor;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-width: 0.1em 0.05em 0.05em 0.1em;
  }
  .loader-28:after {
    content: "";
    display: block;
    width: 0.2em;
    height: 0.2em;
    position: absolute;
    top: 0.4em;
    left: 50%;
    border-radius: 50%;
    box-shadow: -0.07em 0.07em 0 0.1em currentcolor;
    -webkit-animation: 2s loader-28-2 linear infinite;
            animation: 2s loader-28-2 linear infinite;
  }
  
  @-webkit-keyframes loader-28-1 {
    0%,
      100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
    10% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
    }
    20% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  
  @keyframes loader-28-1 {
    0%,
      100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
    10% {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
    }
    20% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @-webkit-keyframes loader-28-2 {
    0%,
      100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    30% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    50% {
      -webkit-transform: transalteX(200%);
              transform: transalteX(200%);
    }
  }
  @keyframes loader-28-2 {
    0%,
      100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    30% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    50% {
      -webkit-transform: transalteX(200%);
              transform: transalteX(200%);
    }
  }
  .loader-29 {
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.1em currentcolor, -0.5em -0.5em 0 -0.4em currentcolor, 0 -0.7em 0 -0.4em currentcolor, 0.5em -0.5em 0 -0.4em currentcolor, -0.5em 0.5em 0 -0.4em currentcolor, 0 0.7em 0 -0.4em currentcolor, 0.5em 0.5em 0 -0.4em currentcolor, -0.7em 0 0 -0.4em currentcolor, 0.7em 0 0 -0.4em currentcolor;
    -webkit-animation: 5s loader-29 linear infinite;
            animation: 5s loader-29 linear infinite;
  }
  
  @-webkit-keyframes loader-29 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-29 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loader-30 {
    border: 0.2em solid transparent;
    border-top-color: currentcolor;
    border-bottom-color: currentcolor;
    border-radius: 50%;
    position: relative;
    -webkit-animation: 1s loader-30 linear infinite;
            animation: 1s loader-30 linear infinite;
  }
  .loader-30:before, .loader-30:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: 0.2em solid transparent;
    border-bottom-color: currentcolor;
  }
  .loader-30:before {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    right: -0.3em;
    top: -0.05em;
  }
  .loader-30:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    left: -0.3em;
    bottom: -0.05em;
  }
  
  @-webkit-keyframes loader-30 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-30 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .loader-31 {
    box-shadow: 0 0 2em currentcolor;
    background-color: currentcolor;
    position: relative;
    border-radius: 50%;
    -webkit-transform: rotateX(-60deg) perspective(1000px);
            transform: rotateX(-60deg) perspective(1000px);
  }
  .loader-31:before, .loader-31:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    -webkit-animation: 1s loader-31 ease-out infinite;
            animation: 1s loader-31 ease-out infinite;
  }
  .loader-31:after {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  
  @-webkit-keyframes loader-31 {
    0% {
      opacity: 1;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor;
    }
    100% {
      opacity: 0;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: -1em -1em 0 -0.35em currentcolor, 0 -1.5em 0 -0.35em currentcolor, 1em -1em 0 -0.35em currentcolor, -1.5em 0 0 -0.35em currentcolor, 1.5em -0 0 -0.35em currentcolor, -1em 1em 0 -0.35em currentcolor, 0 1.5em 0 -0.35em currentcolor, 1em 1em 0 -0.35em currentcolor;
    }
  }
  
  @keyframes loader-31 {
    0% {
      opacity: 1;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      box-shadow: 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor, 0 0 0 -0.5em currentcolor;
    }
    100% {
      opacity: 0;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      box-shadow: -1em -1em 0 -0.35em currentcolor, 0 -1.5em 0 -0.35em currentcolor, 1em -1em 0 -0.35em currentcolor, -1.5em 0 0 -0.35em currentcolor, 1.5em -0 0 -0.35em currentcolor, -1em 1em 0 -0.35em currentcolor, 0 1.5em 0 -0.35em currentcolor, 1em 1em 0 -0.35em currentcolor;
    }
  }
  .loader-32 {
    position: relative;
    border-radius: 50%;
    box-shadow: 0 0 1em 0 currentcolor, inset 0 0 1em 0 currentcolor;
    -webkit-animation: 1s loader-32 linear infinite;
            animation: 1s loader-32 linear infinite;
  }
  .loader-32:before, .loader-32:after {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    border-radius: 50%;
  }
  .loader-32:before {
    border-top: 0.2em solid currentcolor;
    border-right: 0.2em solid transparent;
    top: 0.28em;
    right: calc(50% - 0.22em);
  }
  .loader-32:after {
    border-bottom: 0.2em solid currentcolor;
    border-left: 0.2em solid transparent;
    bottom: 0.28em;
    left: calc(50% - 0.22em);
  }
  
  @-webkit-keyframes loader-32 {
    0% {
      -webkit-transform: rotateX(-60deg) rotateZ(0deg);
              transform: rotateX(-60deg) rotateZ(0deg);
    }
    100% {
      -webkit-transform: rotateX(-60deg) rotateZ(360deg);
              transform: rotateX(-60deg) rotateZ(360deg);
    }
  }
  
  @keyframes loader-32 {
    0% {
      -webkit-transform: rotateX(-60deg) rotateZ(0deg);
              transform: rotateX(-60deg) rotateZ(0deg);
    }
    100% {
      -webkit-transform: rotateX(-60deg) rotateZ(360deg);
              transform: rotateX(-60deg) rotateZ(360deg);
    }
  }
  .loader-33 {
    border-radius: 50%;
    position: relative;
  }
  .loader-33:after, .loader-33:before {
    position: absolute;
    content: "";
  }
  .loader-33:after {
    height: 0.1em;
    width: 1em;
    background-color: currentcolor;
    border-radius: 0.1em;
    bottom: 0;
    left: 0;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-animation: loader-33-1 0.8s ease-in-out infinite alternate;
            animation: loader-33-1 0.8s ease-in-out infinite alternate;
  }
  .loader-33:before {
    height: 0.2em;
    width: 0.2em;
    background-color: currentcolor;
    border-radius: 50%;
    top: 0;
    left: calc(50% - 0.1em);
    -webkit-animation: loader-33-2 0.4s ease-in-out infinite alternate;
            animation: loader-33-2 0.4s ease-in-out infinite alternate;
  }
  
  @-webkit-keyframes loader-33-2 {
    0% {
      height: 0.24em;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    75% {
      height: 0.2em;
      width: 0.2em;
    }
    100% {
      height: 0.1em;
      width: 0.24em;
      -webkit-transform: translateY(0.8em);
              transform: translateY(0.8em);
    }
  }
  
  @keyframes loader-33-2 {
    0% {
      height: 0.24em;
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    75% {
      height: 0.2em;
      width: 0.2em;
    }
    100% {
      height: 0.1em;
      width: 0.24em;
      -webkit-transform: translateY(0.8em);
              transform: translateY(0.8em);
    }
  }
  @-webkit-keyframes loader-33-1 {
    0% {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
    }
    100% {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
  }
  @keyframes loader-33-1 {
    0% {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
    }
    100% {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
    }
  }
  .loader-34 {
    position: relative;
    width: 1em;
    height: 0.5em;
  }
  .loader-34:after, .loader-34:before {
    position: absolute;
    content: "";
    height: 0.4em;
    width: 0.4em;
    top: 0;
    background-color: currentcolor;
    border-radius: 50%;
  }
  .loader-34:after {
    right: 0;
    -webkit-animation: loader-34-2 0.5s ease-in-out infinite;
            animation: loader-34-2 0.5s ease-in-out infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
  }
  .loader-34:before {
    left: 0;
    -webkit-animation: loader-34-1 0.5s ease-in-out infinite;
            animation: loader-34-1 0.5s ease-in-out infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
  }
  
  @-webkit-keyframes loader-34-1 {
    0% {
      -webkit-transform: translatex(0px);
              transform: translatex(0px);
    }
    65% {
      height: 0.4em;
      width: 0.4em;
    }
    100% {
      height: 0.5em;
      width: 0.3em;
      -webkit-transform: translatex(0.2em);
              transform: translatex(0.2em);
    }
  }
  
  @keyframes loader-34-1 {
    0% {
      -webkit-transform: translatex(0px);
              transform: translatex(0px);
    }
    65% {
      height: 0.4em;
      width: 0.4em;
    }
    100% {
      height: 0.5em;
      width: 0.3em;
      -webkit-transform: translatex(0.2em);
              transform: translatex(0.2em);
    }
  }
  @-webkit-keyframes loader-34-2 {
    0% {
      -webkit-transform: translatex(0px);
              transform: translatex(0px);
    }
    65% {
      height: 0.4em;
      width: 0.4em;
    }
    100% {
      height: 0.5em;
      width: 0.3em;
      -webkit-transform: translatex(-0.2em);
              transform: translatex(-0.2em);
    }
  }
  @keyframes loader-34-2 {
    0% {
      -webkit-transform: translatex(0px);
              transform: translatex(0px);
    }
    65% {
      height: 0.4em;
      width: 0.4em;
    }
    100% {
      height: 0.5em;
      width: 0.3em;
      -webkit-transform: translatex(-0.2em);
              transform: translatex(-0.2em);
    }
  }
  .loader-35 {
    margin: 0 0.5em;
    position: relative;
  }
  .loader-35:before {
    border-radius: 50%;
    background-color: currentcolor;
    -webkit-animation: loader-35 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
            animation: loader-35 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    content: "";
    width: inherit;
    height: inherit;
    top: 0;
    left: 0;
    position: absolute;
  }
  
  @-webkit-keyframes loader-35 {
    0% {
      -webkit-transform: translateX(0) scale(1);
              transform: translateX(0) scale(1);
    }
    25% {
      -webkit-transform: translateX(-100%) scale(0.3);
              transform: translateX(-100%) scale(0.3);
    }
    50% {
      -webkit-transform: translateX(0) scale(1);
              transform: translateX(0) scale(1);
    }
    75% {
      -webkit-transform: translateX(100%) scale(0.3);
              transform: translateX(100%) scale(0.3);
    }
    100% {
      -webkit-transform: translateX(0) scale(1);
              transform: translateX(0) scale(1);
    }
  }
  
  @keyframes loader-35 {
    0% {
      -webkit-transform: translateX(0) scale(1);
              transform: translateX(0) scale(1);
    }
    25% {
      -webkit-transform: translateX(-100%) scale(0.3);
              transform: translateX(-100%) scale(0.3);
    }
    50% {
      -webkit-transform: translateX(0) scale(1);
              transform: translateX(0) scale(1);
    }
    75% {
      -webkit-transform: translateX(100%) scale(0.3);
              transform: translateX(100%) scale(0.3);
    }
    100% {
      -webkit-transform: translateX(0) scale(1);
              transform: translateX(0) scale(1);
    }
  }
  .loader-36 {
    position: relative;
  }
  .loader-36:before, .loader-36:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
  }
  .loader-36:before {
    width: 1em;
    height: 1em;
    border: 0.1em solid currentcolor;
    border-radius: 50%;
    -webkit-animation: loader-36-1 1.15s infinite -0.3s;
            animation: loader-36-1 1.15s infinite -0.3s;
  }
  .loader-36:after {
    right: 0;
    bottom: 0;
    margin: auto;
    width: 0;
    height: 0;
    border: 0.1em solid currentcolor;
    border-radius: 50%;
    -webkit-transform: translate(-0.2em);
            transform: translate(-0.2em);
    -webkit-animation: loader-36-2 4.6s infinite steps(1);
            animation: loader-36-2 4.6s infinite steps(1);
  }
  
  @-webkit-keyframes loader-36-1 {
    to {
      -webkit-transform: rotateX(180deg);
              transform: rotateX(180deg);
    }
  }
  
  @keyframes loader-36-1 {
    to {
      -webkit-transform: rotateX(180deg);
              transform: rotateX(180deg);
    }
  }
  @-webkit-keyframes loader-36-2 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      box-shadow: 0.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0.2em 0 0 currentcolor, 0.4em 0 0 currentcolor;
    }
  }
  @keyframes loader-36-2 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      box-shadow: 0.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0.2em 0 0 currentcolor, 0.4em 0 0 currentcolor;
    }
  }
  .loader-37 {
    border-right: 0.1em solid currentcolor;
    border-radius: 100%;
    -webkit-animation: loader-37 800ms linear infinite;
            animation: loader-37 800ms linear infinite;
  }
  .loader-37:before, .loader-37:after {
    content: "";
    width: 0.8em;
    height: 0.8em;
    display: block;
    position: absolute;
    top: calc(50% - 0.4em);
    left: calc(50% - 0.4em);
    border-left: 0.08em solid currentcolor;
    border-radius: 100%;
    animation: loader-37 400ms linear infinite reverse;
  }
  .loader-37:after {
    width: 0.6em;
    height: 0.6em;
    top: calc(50% - 0.3em);
    left: calc(50% - 0.3em);
    border: 0;
    border-right: 0.05em solid currentcolor;
    -webkit-animation: none;
            animation: none;
  }
  
  @-webkit-keyframes loader-37 {
    from {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    to {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  
  @keyframes loader-37 {
    from {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    to {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  .loader-38 {
    height: 0.1em;
    width: 0.1em;
    box-shadow: -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor;
    -webkit-animation: loader-38 6s infinite;
            animation: loader-38 6s infinite;
  }
  
  @-webkit-keyframes loader-38 {
    0% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor;
    }
    8.33% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor;
    }
    16.66% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor;
    }
    24.99% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    33.32% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor;
    }
    41.65% {
      box-shadow: 0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor;
    }
    49.98% {
      box-shadow: 0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor;
    }
    58.31% {
      box-shadow: -0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    66.64% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    74.97% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    83.3% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    91.63% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    100% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor;
    }
  }
  
  @keyframes loader-38 {
    0% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor;
    }
    8.33% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor;
    }
    16.66% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor;
    }
    24.99% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    33.32% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor;
    }
    41.65% {
      box-shadow: 0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor;
    }
    49.98% {
      box-shadow: 0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor;
    }
    58.31% {
      box-shadow: -0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    66.64% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    74.97% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    83.3% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, 0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    91.63% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor, -0.2em 0.2em 0 0.1em currentcolor;
    }
    100% {
      box-shadow: -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor, -0.2em -0.2em 0 0.1em currentcolor;
    }
  }
  .loader-39 {
    position: relative;
    width: 0.15em;
    height: 0.15em;
    background-color: currentcolor;
    border-radius: 100%;
    -webkit-animation: loader-39-1 30s infinite linear;
            animation: loader-39-1 30s infinite linear;
  }
  .loader-39:before, .loader-39:after {
    content: "";
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .loader-39:before {
    width: 0.3em;
    height: 1em;
    -webkit-animation: loader-39-2 0.8s linear infinite;
            animation: loader-39-2 0.8s linear infinite;
  }
  .loader-39:after {
    width: 1em;
    height: 0.3em;
    -webkit-animation: loader-39-2 1.2s linear infinite;
            animation: loader-39-2 1.2s linear infinite;
  }
  
  @-webkit-keyframes loader-39-1 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes loader-39-1 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader-39-2 {
    0% {
      box-shadow: 0.04em -0.04em 0 0.02em currentcolor;
    }
    25% {
      box-shadow: 0.04em 0.04em 0 0.02em currentcolor;
    }
    50% {
      box-shadow: -0.04em 0.04em 0 0.02em currentcolor;
    }
    75% {
      box-shadow: -0.04em -0.04em 0 0.02em currentcolor;
    }
    100% {
      box-shadow: 0.04em -0.04em 0 0.02em currentcolor;
    }
  }
  @keyframes loader-39-2 {
    0% {
      box-shadow: 0.04em -0.04em 0 0.02em currentcolor;
    }
    25% {
      box-shadow: 0.04em 0.04em 0 0.02em currentcolor;
    }
    50% {
      box-shadow: -0.04em 0.04em 0 0.02em currentcolor;
    }
    75% {
      box-shadow: -0.04em -0.04em 0 0.02em currentcolor;
    }
    100% {
      box-shadow: 0.04em -0.04em 0 0.02em currentcolor;
    }
  }
  .loader-40 {
    border: 0.05em currentcolor solid;
    border-radius: 0.2em;
    overflow: hidden;
    position: relative;
  }
  .loader-40:after, .loader-40:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    width: inherit;
    height: inherit;
    -webkit-animation: loader-40 2s infinite linear;
            animation: loader-40 2s infinite linear;
  }
  .loader-40:before {
    border-top: 0.2em currentcolor solid;
    top: -0.15em;
    left: calc(-50% - 0.15em);
    -webkit-transform-origin: right center;
            transform-origin: right center;
  }
  .loader-40:after {
    border-bottom: 0.2em currentcolor solid;
    top: 0.15em;
    right: calc(-50% - 0.15em);
    -webkit-transform-origin: left center;
            transform-origin: left center;
  }
  
  @-webkit-keyframes loader-40 {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  
  @keyframes loader-40 {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  .loader-41 {
    border: 0.05em currentcolor solid;
    border-radius: 0.2em;
    position: relative;
    background: linear-gradient(45deg, transparent 48%, currentcolor 50%, currentcolor 50%, transparent 52%, transparent), linear-gradient(-45deg, transparent 48%, currentcolor 50%, currentcolor 50%, transparent 52%, transparent);
    background-size: 0.5em 0.5em;
    background-position: 0% 0%;
    -webkit-animation: loader-41 1s infinite linear;
            animation: loader-41 1s infinite linear;
  }
  
  @-webkit-keyframes loader-41 {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -1em 0;
    }
  }
  
  @keyframes loader-41 {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -1em 0;
    }
  }
  .loader-42 {
    width: 2em;
    height: 0.66em;
    border: 0.05em currentcolor solid;
    border-radius: 0.1em;
    background: linear-gradient(-60deg, transparent 0%, transparent 50%, currentcolor 50%, currentcolor 75%, transparent 75%, transparent);
    background-size: 1em 2em;
    background-position: 0 0;
    -webkit-animation: loader-42 0.8s infinite linear;
            animation: loader-42 0.8s infinite linear;
  }
  
  @-webkit-keyframes loader-42 {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -2em 0;
    }
  }
  
  @keyframes loader-42 {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -2em 0;
    }
  }
  .box:nth-of-type(n + 43) {
    display: none;
  }
  