
  #_DeleteRecord {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 99999;
    left: 50%;
    top: 30px;
    font-size: 14px;
  }

  #_DeleteRecord.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s;
  }