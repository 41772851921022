.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__month {
  margin: 0.4rem;
  font-size: 10px;
  text-align: center;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  font-size: 10px;
  width: 100%;
  box-sizing: content-box;
}
