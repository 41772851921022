body {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.7;
  color: rgba(0, 0, 0, 0.87);
}
#menu{background:#464646;color:#eee;height:35px;}
#menu ul,#menu li{margin:0;padding:0;list-style:none}
#menu ul{height:35px}
#menu li{float:left;display:inline;position:relative;font: 9px Arial;}
#menu li a{color:#ccc}
#menu a{display:block;line-height:35px;padding:0 14px;text-decoration:none;color:#333;}
#menu li:hover > a,#menu li a:hover{color:#fff}
#menu input{display:none;margin:0 0;padding:0 0;width:80px;height:35px;opacity:0;cursor:pointer}
#menu label{font:bold 30px Arial;display:none;width:35px;height:36px;line-height:36px;text-align:center}
#menu label span{font-size:13px;position:absolute;left:35px}
#menu ul.menus{height:auto;overflow:hidden;width:180px;background:#fff;position:absolute;z-index:99;display:none;border:1px solid #ccc;border-top:none;color:#333}
#menu ul.menus a{color:#333}
#menu ul.menus li{display:block;width:100%;font:12px Arial;text-transform:none;}
#menu li:hover ul.menus{display:block}
#menu a.prett,#menu a.trigger2{padding:0 27px 0 14px}
#menu li:hover > a.prett,#menu a.prett:hover{background:#fff;color:#333}
#menu a.prett::after{content:"";width:0;height:0;border-width:6px 5px;border-style:solid;border-color:#eee transparent transparent transparent;position:absolute;top:15px;right:9px}
#menu ul.menus a:hover{background:#BABABA;}
#menu a.trigger2::after{content:"";width:0;height:0;border-width:5px 6px;border-style:solid;border-color:transparent transparent transparent #eee ;position:absolute;top:13px;right:9px}

@media screen and (max-width: 600px){
#menu{position:relative}
#menu ul{background:#838383;position:absolute;top:100%;right:0;left:0;z-index:3;height:auto;display:none;}
#menu ul.menus{width:100%;position:static;border:none}
#menu li{display:block;float:none;width:auto;text-align:left}
#menu li a{color:#fff}
#menu li a:hover{color:#333}
#menu li:hover{background:#BABABA;color:#333;}
#menu li:hover > a.prett,#menu a.prett:hover{background:#BABABA;color:#333;}
#menu ul.menus a{background:#BABABA;}
#menu ul.menus a:hover{background:#fff;}
#menu input,#menu label{position:absolute;top:0;left:0;display:block}
#menu input{z-index:4}
#menu input:checked + label{color:white}
#menu input:checked ~ ul{display:block}
}


.card {
    position: relative;
    margin-bottom: 24px;
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.profile-sidebar {
  float: left;
  width: 300px;
  margin-right: 20px;
  margin-left: 5px;
}

/* define a fixed width for the entire menu */
.navigation {
  width: 250px;
}

/* reset our lists to remove bullet points and padding */
.mainmenu,
.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* make ALL links (main and submenu) have padding and background color */

.mainmenu a {
  display: block;
  background-color: white;
  text-decoration: none;
  padding: 14px 10px;
  color: #2f4f4f;
}

/* add hover behaviour */
.mainmenu a:hover {
  background-color: #f1f1f1;
  display: block;
}

/* when hovering over a .mainmenu item,
  display the submenu inside it.
  we're changing the submenu's max-height from 0 to 200px;
*/

.mainmenu li:hover .submenu {
  display: block;
  margin-left: 20px;
  max-height: 370px;
}

/*
  we now overwrite the background-color for .submenu links only.
  CSS reads down the page, so code at the bottom will overwrite the code at the top.
*/

.submenu a {
  background-color: #f1f1f1;
}

/* hover behaviour for links inside .submenu */
.submenu a:hover {
  background-color: #eee;
}

/* this is the initial state of all submenus.
  we set it to max-height: 0, and hide the overflowed content.
*/
.submenu {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.5s ease-out;
}
