/* Full-width input fields */
.inputt {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: none;
    background: #f1f1f1;
    color: black;
}

/* Add a background color when the inputs get focus */
.inputt:focus {
    background-color: #ddd;
    outline: none;
}


.confirmInputt {
    width: 100%;
    padding: 15px;
    margin: 5px 0 1px 0;
    display: inline-block;
    background: #f1f1f1;
    color: black;
}

.inputt::placeholder,
.confirmInputt::placeholder {
    color: #474e5d;
    opacity: 1;
    /* Firefox */
}

.inputt:-ms-input-placeholder,
.confirmInputt:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #474e5d;
}

/* Set a style for all buttons */
.signupbtn {
    background-color: #15b4e9;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
    float: left;
    width: 50%;
}

.signupbtn:hover {
    opacity: 1;
}

/* Add padding to pgsignup-container elements */
.pgsignup-container {
    padding: 16px;
}

/* The pgsignup (background) */
.pgsignup {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: #474e5d;
    padding-top: 50px;
}

/* pgsignup Content/Box */
.pgsignup-content {
    background-color: #fefefe;
    margin: 5% auto 15% auto;
    /* 5% from the top, 15% from the bottom and centered */
    border: 1px solid #888;
    width: 80%;
    /* Could be more or less, depending on screen size */
}

/* Style the horizontal ruler */
.hrt {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
}

.clearfix {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Clear floats */
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

/* Change styles for cancel button and signup button on extra small screens */
@media screen and (max-width: 300px) {
    .signupbtn {
        width: 100%;
    }
}