.custom-modal-header {
    background: #eaeef5;
    padding: 12px;
    font-weight: 300;
    /* margin-top: -16px !important; */
    font-size: 32px;
    text-align: right;
}

.custom-modal-body {
    padding: 30px;
}

.custom-modal {
    position: absolute;
    width: auto;
    top: 1px;
    left: 1px;
    /* min-width: 500px; */
    /* max-width: 100%; */
    /* inset: 10; */
    z-index: 10;
    background-color: #ffffff;
}