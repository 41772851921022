.input-range {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.input-range__slider {
  width: 100%;
}

.progress,
.progress__bar {
  min-height: 1.25rem;
}

.progress__value {
  position: absolute;
  top: .0625rem;
  right: .25rem;
}

.progress {
  background-color: #545454;
  position: relative;
}

.progress__bar {
  background-color: #43b7e1;
}



*, *:before, *:after {
  box-sizing: border-box;
}


main {
  margin: 0 auto;
  width: 80%;
}
