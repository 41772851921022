.portfolioDisc {
	width: 300px;
    height: 0px;
	padding-top: 0px;
    overflow:hidden;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin-top:300px;
	position:absolute;
	background-color:#000;
	font-size: 15px;
	line-height: 18px;
	text-align:center;
	color: #FFF;
    font-family: FontAwesome, "Open Sans", Verdana, sans-serif;
}

.port {
/* 	background-color: red;
	width:300px;
	height: 300px; */
	position:relative;
	}


.port:hover .portfolioDisc {
	margin-top:221px;
    height:69px;
	padding-top: 10px;
	
}

.test{
    background-color: red;
    border-radius: 5px;
    top: 675px;
    position: fixed;
    margin-left: -10px;
    width: 100%;
    height: 300px;
}