.img-container{
  width:640px;
  height:480px;
  float:left;
}
.cropper-modal {
  background-color: #000;
  opacity: .5;
  width: 100%;
}
.img-preview{
  width: 200px;
  height:200px;
  float: left;
  margin-left: 10px;
}

.progress,
.progress__bar {
  min-height: 10px;
}

.progress__value {
  position: absolute;
  width: 50%;
  top: 0;
  right: 1.50rem;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.7;
  color: #438541;
}

.progress {
  background-color: #f3eded18 !important;
  position: relative;
  width: 50%; 
}

.progress__bar {
  border-radius: 50px 20px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
